@import '../libs/responsive.scss';

#pageInfos {
    display: flex;
    flex-flow: column nowrap;
    margin: 5% auto;
    max-width: 80%;

    h1 {
        text-align: center;
        margin: 2% 0;
    }

    h2 {
        text-align: center;
        margin: 2% 0;
    }

    p {
        text-align: justify;
        margin: 5% 0;
    }

    a {
        text-decoration: underline;
        &:hover {
            text-shadow: 0.5px 0.5px 0.5px black;
        }
    }

    .groupInfos {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        margin: 2% 0;

        .infosText {
            margin: 0 5%;
            align-items: center;
        }

        img {
            object-fit: cover;
            border-radius: 25px;
            width: 40%;
        }
    }

    .infosVisit {
        .infosText {
            display: flex;
        }

        img {
            width: 25%;
            height: 40vh;
            margin: 0 1%;
        }
    }
}

@include media(phone, phone-landscape) {
    #pageInfos {
        max-width: 90%;

        li {
            max-width: 90%;
            margin: 5% auto;
        }

        .groupInfos {
            flex-flow: column-reverse nowrap;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }
        }

        .infosHotel, .infosVol {
            flex-flow: column nowrap;
        }

        .infosVisit {
            .infosText {
                align-items: center;
            }

            img {
                width: 100%;
                height: auto;
                margin: 2% 0;
            }
        }
    }
}
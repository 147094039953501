$xlarge: 1400px!default;
$large: 1200px!default;
$medium: 992px!default;
$small: 768px!default;
$extra-small: 425px!default;

$deskop-large: "only screen and (min-width: #{$xlarge})";
$deskop-format: "only screen and (min-width: #{$large})";
$tablette-format: "all and (min-width: #{$small}) and (max-width: #{$medium})";
$phone-landscape-format: "all and (min-width: #{$extra-small}) and (max-width: #{$small})";
$phone-format: "all and (max-width: #{$extra-small})";

/* Créer les mixins pour les différentes tailles d'écrans */
@mixin phone {
    @media #{$phone-format} {
        @content;
    }
}

@mixin phone-landscape {
    @media #{$phone-landscape-format} {
        @content;
    }
}

@mixin tablet {
    @media #{$tablette-format} {
        @content;
    }
}

@mixin deskop {
    @media #{$deskop-format} {
        @content;
    }
}

@mixin deskop-large {
    @media #{$deskop-large} {
        @content;
    }
}

/* Mixin to use all the format */
/***
 * List of formats :
 * - phone
 * - phone-landscape
 * - tablet
 * - deskop
 */

@mixin media($keys...) {
    @each $key in $keys {
        @if ($key == phone-landscape) {
            @include phone-landscape {
                @content;
            }
        } @else if ($key == phone) {
            @include phone {
                @content;
            }
        } @else if ($key == tablet) {
            @include tablet {
                @content;
            }
        } @else if ($key == deskop) {
            @include deskop {
                @content;
            }
        }@else if ($key == deskop-large) {
            @include deskop-large {
                @content;
            }
        }
    }
}
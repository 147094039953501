@import '../libs/responsive.scss';

header {
    display: flex;
    flex-flow: column wrap;
    position: relative;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9)),
                        url("../../assets/img/header/fondHeader.jpg");

    #banderole {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    #langFilter {
        position: absolute;
        top: -5vw;
        left: 85%;
        width: 10vw;
        height: 0px;
        z-index: 10;
    }
    
    nav {
        width: 100%;
        font-family: 'amita', sans-serif;

        ul {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;

            li {
                padding: 20px 40px;
                font-size: 1.5vw;
                list-style-type: none;

                a {
                    position: relative;
                }
    
                a:before {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                    background-color: #000;
                    visibility: hidden;
                    transition: all 0.3s ease-in-out;
                }
    
                a:hover {
                    text-shadow: 0.5px 0.5px 0 rgba(0, 0, 0, 0.5);
                }
    
                a:hover:before {
                    visibility: visible;
                    width: 100%;
                }
            }
        }
    }
}

@include media(phone, phone-landscape) {
    header nav ul li{
        font-size: 5vw;
    }
}
@import '../libs/responsive.scss';

#pageHome {
    display: flex;
    flex-flow: row nowrap;

    .homeImg {
        position: relative;
        width: 40%;

        img {
            height: auto;
            width: 100%;
            object-fit: cover;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }

        #pageHomeRSVP {
            position: absolute;
            text-align: center;
            top: 12%;
            left: 50%;
            transform: translateX(-50%);

            a {
                padding: 1vw 3vw;
                background-color: rgb(251, 228, 227);
                border: 1px solid rgb(239, 189, 192);
                border-radius: 25px;
                &:hover {
                    text-shadow: 0.5px 0.5px rgb(0, 0, 0, 0.5);
                    border: 3px solid rgb(239, 189, 192);
                }
            }
        }
    }

    #homeText {
        padding : 1vw 5vw;
        width: 60%;
    
        #homeDate {
            time {
                padding: 1% 5%;
                font-size: 1.4em;
                font-weight: 600;
            }
        }    
    }
}

@include media(phone, phone-landscape) {
    #pageHome {
        flex-flow: column-reverse nowrap;
        .homeImg {
            width: 100%;

            img {
                border-radius: 0px;
                border-radius: 0px;
            }
    
            #pageHomeRSVP {
                top: 12%;
                left: 50%;
                transform: translateX(-50%);
    
                a {
                    padding: 5vw 24vw;
                }
            }
        }

        #homeText {
            width: 100%;

            h1 {
                margin-top: 1em;
                text-align: center;
            }

            #homeDate {
                text-align: center;
            }

            p:last-child {
                margin: 3em 0;
            }
        }
    }
}

.pageConnexion {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    
    img {
        width: 400px;
        margin: 0 auto;
        object-fit: contain;
        border-radius: 50px;
        margin-bottom: 50px;
    }

    .password-div {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        display: flex;
        gap: 10px;
    
        .password-form {
            display: flex;
            gap: 10px;
    
            .password-input {
                width: 40px;
                height: 40px;
                text-align: center;
                font-size: 24px;
                border-radius: 10px;
                width: 5vw;
                height: 10vh;
    
                &:invalid {
                    border: 5px solid #efbdc0;
    
                }
            }
        }
    
        p {
            color: #86c2cf;
        }

        p:active {
            color: #fbe4e3;
        }
    }
    
    .shake {
        animation: shake 1s ease-in-out;
    }
    
    @keyframes shake {
        0%, 100% { transform: translateX(0); }
        10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
        20%, 40%, 60%, 80% { transform: translateX(10px); }
    }
    
    .validationText {
        text-align: center;
        color: #efbdc0;
        font-style: italic;
    }
}

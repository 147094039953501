@import './responsive.scss';

@font-face {
    font-family: 'Niconne';
    src: url(../../assets/fonts/Niconne/Niconne-Regular.ttf);
}

@font-face {
    font-family: 'Rochester';
    src: url(../../assets/fonts/Rochester/Rochester-Regular.ttf);
}

@font-face {
    font-family: 'CremeEspana';
    src: url(../../assets/fonts/creme_espana/Creme-Espana.ttf);
}

@font-face {
    font-family: 'kenokyCoffekan';
    src: url(../../assets/fonts/kenoky_coffekan/CoffekanRegular.ttf);
}

@font-face {
    font-family: 'openSans';
    src: url(../../assets/fonts/Open_Sans/OpenSans-VariableFont.ttf)
}

@font-face {
    font-family: 'amita';
    src: url(../../assets/fonts/Amita/Amita-Regular.ttf);
}

#title {
    font-family: 'Rochester', cursive;
    font-size: 8vw;
    margin: 5vw auto;
}

h1 {
    font-family: 'Niconne', cursive;
    font-size: 3vw;
    font-weight: 700;
    margin: 3vw 0;
}

h2 {
    font-size: 2vw;
    margin: 5% 0;
}

p {
    font-family: 'openSans', sans-serif;
    font-size: 1.5vw;
    margin: 2vw 0;
    text-align: justify;
}

ul {
    list-style-type: disc;
    font-family: 'openSans', sans-serif;

    li {
        font-size: 1.5vw;
        margin: 1vw 0;
        text-align: justify;
    }
}

i {
    font-style: italic;
}

@include media(phone, phone-landscape) {
    #title {
        font-size: 20vw;
        margin: 5vw auto;
    }
    
    h1 {
        font-size: 13vw;
        font-weight: 700;
        margin: 3vw 0;
    }
    
    h2 {
        font-size: 9vw;
        margin: 5% 0;
    }
    
    p {
        font-size: 5vw;
        margin: 2vw 0;
    }
    
    ul li {
        font-size: 5vw;
        margin: 1vw 0;
    }
}
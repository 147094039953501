@import '../libs/responsive.scss';

#pageWeeding {
    display: flex;
    flex-flow: column nowrap;
    margin: 2% auto 5%;

    h1 {
        text-align: center;
        margin: 2% 0;
    }

    .leaflet-container {
        width: 70%;
        height: 50vh;
        margin: 2% auto;
        border-radius: 15px;

        a {
            text-decoration: none;
        }
    }

    & > div {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-around;
        padding: 0 5%;
        margin: 2% auto;

        & > p {
            width: 40%;
        }

        & > img {
            object-fit: cover;
            width: 40%;
            max-height: 50vh;
            border-radius: 15px;
        }
    }

    a {
        text-decoration: underline;
        &:hover {
            text-shadow: 0.5px 0.5px 0.5px black;
        }
    }

    .weedingDate {
        display: flex;
        flex-flow: column nowrap;

        p {
            text-align: center;
            margin: 2% 0;
        }

        p:last-child {
            text-align: left;
            width: 60%;
        }
    }

    #imageDressCode {
        width: 50%;
        margin: auto;
        margin: 2% 2%;
        border: 0px solid white;
        border-radius: 15px;
    }
}

@include media(phone, phone-landscape) {
    #pageWeeding {
        margin: 0;
        margin-bottom: 5%;
    
        .leaflet-container {
            width: 100%;
            height: 50vh;
            border-radius: 0;
        }
    
        & > div {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: space-around;
            margin: 0 auto;
    
            & > p {
                width: 100%;
            }
    
            & > img {
                object-fit: cover;
                width: 100%;
                border-radius: 15px;
                margin-top: 3%;
            }
        }
    
        .weedingDate {
            p {
                margin: 5% 0;
            }

            p:last-child {
                width: 100%;
            }
        }

        #imageDressCode {
            width: 90%;
            margin: 5% auto;
            border: 0px solid white;
            border-radius: 15px;
        }

        #divDressCode {
            flex-flow: column-reverse;
        }
    }
}
@import '../libs/responsive.scss';

#pageParticipation {
    display: flex;
    flex-flow: column nowrap;
    margin: 5% auto;
    max-width: 80%;

    h1 {
        text-align: center;
        margin: 2% 0;
    }

    p {
        text-align: center;
    }

    p.indic {
        font-size: medium;
        font-style: italic;

        a {
            color: blue;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    #participationForm {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        form {
            display: flex;
            flex-flow: column nowrap;
            width: 40%;
            margin: auto;

            .formYesNo {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-around;
                margin-bottom: 2vw;

                input[type="radio"] {
                    opacity: 0;
                    position: fixed;
                    width: 0;
                    height: 0;
                }

                label {
                    display: inline-block;
                    background-color: #ddd;
                    padding: 10px 20px;
                    font-family: sans-serif, Arial;
                    font-size: 16px;
                    border: 2px solid #444;
                    border-radius: 4px;

                    &:hover {
                        background-color: #fbe4e3;
                        cursor: pointer;
                    }
                }

                input[type="radio"]:checked + label {
                    background-color:#fbe4e3;
                    border-color: #EFBDC0;
                }

                input[type="radio"]:focus + label {
                    border: 2px dashed #444;
                }
            }
            
            label {
                font-size: 1.5vw;
            }
            
            input, textarea {
                margin-bottom: 2vw;
                margin-top: 0.5vw;
                padding: 1vw;
                border-radius: 15px;
            }
            
            button {
                width: 50%;
                margin: auto;
                border-radius: 25px;
                padding: 1vw 3vw;
                background-color: rgb(229, 136, 136);
                color: rgb(255, 231, 231);
    
                font-size: 1.8vw;
                font-weight: 600;
    
                cursor: pointer;
    
                &:hover {
                    text-shadow: 1px 1px 1px white;
                    box-shadow: 1px 1px 1px 1px black;
                }
            }

            .input_valid {
                border: 2px solid #28792f;
            }
            
            .input_error {
                border: 2px solid #a13540;
                margin: 0;
            }
            
            .error_message {
                color: #a13540;
                display: block;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                font-family: Roboto, sans-serif;
                padding: 0px;
                line-height: 15px !important;
                margin: 0px;
                margin-bottom: 2em;
            }
        }

        img {
            width: 40%;
            margin: 2% 0;
            object-fit: cover;
            border-radius: 25px;
        }
    }
    
    #participationLimitDate {
        color: rgb(229, 136, 136);
        font-weight: 600;
        text-decoration: underline;
    }
}

@include media(phone, phone-landscape) {
    #pageParticipation {
        max-width: 100%;

        p {
            width: 90%;
            margin: 10% auto;
        }

        #participationForm {
            flex-flow: column nowrap;
            align-items: center;
            
            form {
                width: 90%;

                label {
                    font-size: 5vw;
                    margin: 2vw 0;
                }

                input, textarea {
                    margin-bottom: 2vw;
                    margin-top: 0.5vw;
                    padding: 5vw;
                    font-size: 5vw;
                    border-radius: 15px;
                }

                button {
                    width: 80%;
                    padding: 5vw 12vw;
                    font-size: 8vw;
                    margin-top: 5%;
                }
            }
            
            img {
                margin-top: 10%;
                width: 80%;
            }
        }
    }    
}

@import 'libs/style_reset_mini.scss';
@import 'libs/fonts.scss';

body {
    background-color: #F8F8F8;
    font-family: "Times New Roman", Times, serif;
}

a { 
    text-decoration: none;
    color: black;
}
@import '../libs/responsive.scss';

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    background-color: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    width: 500px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
}

@include media(tablet) {
    .modal-content {
        width: 80%;
    }
}
@import '../libs/responsive.scss';

#pageActivities {
    display: flex;
    flex-flow: column nowrap;
    margin: 5% auto;
    max-width: 70%;

    h1, h2 {
        text-align: center;
        margin: 2% 0;
    }

    p {
        text-align: center;
    }

    ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;

        li {
            list-style: none;

            figure {
                position: relative;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 25px;
                }

                figcaption {
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    align-items: center;
                    justify-content: center;

                    color: white;
                    font-size: 1.5em;
                    font-weight: bold;
                    background-color: rgba(112, 112, 112, 0.6);
                    border-radius: 25px;
                }
            }
        }

        .activityElement {
            width: 20%;
            height: 25vh;
        }

        .visitElement {
            width: 46%;
            height: 30vh;

            figcaption p {
                font-size: 1.3em;
                padding: 8%;

                i {
                    font-size: 0.6em;
                }
            }
        }

        .hotelElement {
            width: 24%;
            height: 40vh;

            figcaption p {
                padding: 5%;
                font-size: 1.2em;

                a {
                    color: white;
                    cursor: pointer;
    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            
        }
    }
}

@include media(phone, phone-landscape) {
    #pageActivities {
        max-width: 90%;

        ul {
            flex-flow: column nowrap;
            align-items: center;

            .activityElement {
                width: 90%;
                height: 40vh;
            }

            .visitElement {
                width: 90%;
                height: 50vh;
            }

            .hotelElement {
                width: 90%;
                height: 60vh;
            }
        }
    }
}